import CancelIcon from "@mui/icons-material/Cancel";
import { Dialog, FormControlLabel } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MailingAddressInput } from "@/types";
import countries from "@/utils/countries";
import theme from "@/utils/theme";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
// import { ButtonWrapper, InputName } from "./styles";
// import { getValidation } from "./validation";
import { TFunction } from "i18next";
import * as yup from "yup";

export const ButtonWrapper = styled(`div`)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

export const InputName = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
}));

const phoneNumberRegex = /^[+\d](?:.*\d)?$/;

export const getValidation = (t: TFunction<"FormErrors">) => {
  return yup.object().shape({
    phone: yup
      .string()
      .optional()
      .trim()
      .matches(phoneNumberRegex, t("onlyNumbers") || "")
      .min(8, t("phone") || ""),
    lastName: yup.string().optional().trim(),
    email: yup.string().optional().trim(),
    firstName: yup.string().optional().trim(),
    id: yup.string().optional(),
    companyName: yup.string().optional(),
  });
};

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

interface IProps {
  handleClose: () => void;
  open: boolean;
  handleSubmit: (address: MailingAddressInput) => void;
  addressToEdit?: MailingAddressInput;
  edit: boolean;
}

interface IBlurred {
  country: boolean;
  firstName: boolean;
  lastName: boolean;
  address: boolean;
  city: boolean;
  zip: boolean;
  phone: boolean;
}

const initialFormData: MailingAddressInput = {
  email: "",
  firstName: "",
  id: "",
  lastName: "",
  phone: "",
  companyName: "",
};

const Section = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const InputWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const TextInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  fontSize: "14px",
}));

const EditCustomerModal: React.FunctionComponent<IProps> = ({
  handleClose,
  open,
  handleSubmit,
  addressToEdit,
  edit,
}: IProps) => {
  const getInitialForm =
    edit && addressToEdit ? addressToEdit : initialFormData;
  const { t } = useTranslation("Address");
  const { t: tFormErrors } = useTranslation("FormErrors");

  const [actionState, setActionState] = React.useState<{
    isLoading: boolean;
  }>({
    isLoading: false,
  });

  const schema = getValidation(tFormErrors);
  const resolver = useYupValidationResolver(schema);

  const {
    control,
    reset,
    handleSubmit: formSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm<MailingAddressInput>({
    resolver,
    defaultValues: getInitialForm,
  });

  useEffect(() => {
    if (addressToEdit) {
      reset(addressToEdit);
    }
  }, [addressToEdit]);

  const renderButtonName = edit ? t("Edit.button") : t("Add.button");

  const handleFormSubmit = (values: MailingAddressInput) => {
    handleSubmit(values);
    handleClose();
    reset(getInitialForm);
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={"body"} fullWidth>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: { xs: "100%", md: "600px" },
          padding: theme.spacing(2, 2, 0, 2),
        }}
      >
        <CancelIcon
          onClick={handleClose}
          style={{
            width: 20,
            height: 20,
            fill: theme.colors.grey,
            cursor: "pointer",
          }}
        />
      </Box>
      <DialogTitle>
        <Typography
          sx={{
            fontSize: theme.typography.pxToRem(26),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(-2),
          }}
        >
          {edit ? t("Edit.title") : t("Add.title")}
        </Typography>
      </DialogTitle>
      <form autoComplete="on" onSubmit={formSubmit(handleFormSubmit)}>
        <DialogContent dividers={false}>
          <div>
            <Section
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  justifyContent: "space-between",
                },
              }}
            >
              <Box sx={{ width: { xs: "100%", md: "48%" } }}>
                <InputName>{t("firstName")}</InputName>

                <Controller
                  control={control}
                  name="firstName"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <InputWrapper>
                      <TextInput
                        placeholder={t("firstNamePlaceholder") || ""}
                        autoComplete="given-name"
                        value={value}
                        variant="outlined"
                        onBlur={onBlur}
                        onChange={onChange}
                      />
                    </InputWrapper>
                  )}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                <InputName>{t("lastName")}</InputName>

                <Controller
                  control={control}
                  name="lastName"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <InputWrapper>
                      <TextInput
                        placeholder={t("lastNamePlaceholder") || ""}
                        autoComplete="given-name"
                        value={value}
                        variant="outlined"
                        onBlur={onBlur}
                        onChange={onChange}
                      />
                    </InputWrapper>
                  )}
                />
              </Box>
            </Section>

            <Section>
              <InputName>Email</InputName>

              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <InputWrapper>
                    <TextField
                      fullWidth
                      type="text"
                      autoComplete="email"
                      placeholder="hello@myemail.com"
                      value={value}
                      variant="outlined"
                      onBlur={onBlur}
                      onChange={onChange}
                      InputProps={{ sx: { marginBottom: 2 } }}
                    />
                  </InputWrapper>
                )}
              />
            </Section>

            <Section
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  justifyContent: "space-between",
                },
              }}
            >
              <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                <InputName>{t("phoneNumber")}</InputName>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <InputWrapper>
                      <TextField
                        fullWidth
                        autoComplete="tel"
                        placeholder={t("phoneNumberPlaceholder") || ""}
                        value={value}
                        variant="outlined"
                        onBlur={onBlur}
                        onChange={onChange}
                      />
                    </InputWrapper>
                  )}
                />
              </Box>
            </Section>

            <Section>
              <InputName>{t("company")}</InputName>
              <Controller
                control={control}
                name="companyName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <InputWrapper>
                    <TextInput
                      fullWidth
                      type="text"
                      autoComplete="organization"
                      placeholder={t("companyPlaceholder") || ""}
                      value={value}
                      variant="outlined"
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                  </InputWrapper>
                )}
              />
            </Section>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonWrapper>
            <Button
              type="submit"
              disabled={!isValid}
              variant="contained"
              // onClick={() => {

              //   // setIsDisabled(true);
              //   // setActionState({ isLoading: true });
              //   handleSubmit(values, () => {
              //     edit ? handleEditSubmit() : handleAddSubmit();
              //   });
              // }}
            >
              {actionState.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                renderButtonName
              )}
            </Button>
          </ButtonWrapper>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCustomerModal;
