import {
  IPublicApprovedArtwork,
  IPublicApprovedArtworkListResponse,
  IPublicOrder,
  IPublicOrderLineItem,
} from "@kartdavid/corkscrew-types/public";

export enum EModalActions {
  none,
  changePassword,
  changeDetails,
  addAddress,
  addPaymentMethod,
  preferenceLanguage,
  preferenceCurrency,
  editAddress,
}

export enum EEditModalOrder {
  language,
  currency,
  units,
  alertFrequency,
}

export interface IPreferenceFormData {
  language: string;
  currency: string;
  units: string;
  alertFrequency: string;
  textAlerts: true | false;
  whiteLabelPackaging: true | false;
}

export interface IPayment {
  visaEnding: number;
  expiryDate: string;
  nameOnCard: string;
  default: boolean;
}

export type MaterialType = {
  materialSku: string;
  id: string;
  name: string;
  sku: string;
};

export type IStep =
  | "review"
  | "changes"
  | "start-production"
  | "design-review"
  | "review-complete"
  | "printing";

export type ProofStatues =
  | "awaitingArtwork"
  | "not-required"
  | "partially-approved"
  | "sent"
  | "rejected"
  | "proofPending"
  | "approved"
  | "not-sent";

export type QuoteInterface = {
  w: number;
  h: number;
  u: string;
  materialSku: string;
  productSku: string;
  quantity: number;
  currencyCode: string;
  shipToCountryCode: string;
  shape?: string;
};

// export interface IPublicOrderExt extends IPublicOrder {
//   lineItems: (IPublicOrderLineItem & {
//     meta: ItemMeta[];
//   } & any)[];

//   meta: {
//     pagination: string;
//     links: {
//       current: string;
//       invoiceUrl: string;
//     };
//   };
// }

export interface IPublicOrderExt extends IPublicOrder {
  name: string;
  manufacturingStatus: any;
  approved: boolean;
  readyForApproval: boolean;
  allLinesApproved: boolean;

  lineItems: (IPublicOrderLineItem & {
    meta: ItemMeta[];
  } & any)[];
}

export interface ItemMeta {
  id: number;
  ns: string;
  key: string;
  value: string;
  type: string;
}

export type PublicApprovedArtwork = IPublicApprovedArtwork & {
  meta: ItemMeta[];
};

export interface PublicApprovedArtworkListResponse
  extends IPublicApprovedArtworkListResponse {
  data: PublicApprovedArtwork[];
}

export type MailingAddressInput = {
  address1?: string | null | undefined;
  address2?: string | null | undefined;
  city?: string | null | undefined;
  companyName?: string | null | undefined;
  countryCode?: string | null | undefined;
  customerId?: string | null | undefined;
  email?: string | null | undefined;
  firstName?: string | null | undefined;
  id?: string | null | undefined;
  isDefaultBillingAddress?: boolean | null | undefined;
  lastName?: string | null | undefined;
  name?: string | null | undefined;
  phone?: string | null | undefined;
  province?: string | null | undefined;
  zip?: string | null | undefined;
};
