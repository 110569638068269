import { PROOF_ID } from "@/constants";
import {
  IPublicOrderExt,
  MailingAddressInput,
  PublicApprovedArtwork,
  PublicApprovedArtworkListResponse,
} from "@/types";
import { getCorkscrewBaseUrl } from "@/utils/config";
import { decode } from "@/utils/gid";
import type {
  IPublicAddress,
  IPublicAddressCreateRequest,
  IPublicAddressCreateResponse,
  IPublicAddressListResponse,
  IPublicAddressUpdateRequest,
  IPublicApprovedArtworkUpdateRequest,
  IPublicOrderGetResponse,
  IPublicOrderLineItem,
  IPublicOrderListResponse,
  IPublicProofAcceptRequest,
  IPublicProofAcceptResponse,
  IPublicProofGetResponse,
  IPublicProofRejectRequest,
  IPublicUserGetMeResponse,
} from "@kartdavid/corkscrew-types/public";
import axios from "axios";

export interface ProofStatus {
  id: string;
  name: string;
  slug: string;
}

export interface OrderListNode {
  id: string;
  name: string;
  orderNumber: string;
  statusName: string;
  createdAt: Date | undefined;
  lineItems: IPublicOrderLineItem[];
  proofStatus: ProofStatus;
}

export interface OrderListEdge {
  cursor: string;
  node: OrderListNode;
}

export interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}

export interface OrderListResponse extends IPublicOrderListResponse {
  edges: OrderListEdge[];
  pageInfo: PageInfo;
  totalCount: number;
}

interface OrderGetResponse extends IPublicOrderGetResponse {
  data: IPublicOrderExt | undefined;
}

export const newAddressIds = ["new", "new:shipping", "new:billing"];

export const corkscrewClient = (
  apiEndpoint: string,
  orgId: string,
  token: string
) => {
  return axios.create({
    baseURL: `/api`,
    headers: {
      Authorization: "Bearer " + token,
      "x-api-endpoint": apiEndpoint,
      "x-org-id": orgId,
    },
  });
};

export const corkscrewApiFactory = (
  apiEndpoint: string,
  orgId: string,
  token: string
) => {
  const apiClient = corkscrewClient(apiEndpoint, orgId, token);

  const addAddress = (address: MailingAddressInput, customerId: string) => {
    const payload: any = { address, customerId };
    return apiClient.post<IPublicAddressCreateResponse>(`/v1/address`, payload);
  };

  const editAddress = (address: any) => {
    const payload: IPublicAddressUpdateRequest = { address };
    const addressId =
      address.id.replace("gid:/glide/MailingAddress/", "") || address.id;
    return apiClient.post<IPublicAddressUpdateRequest>(
      `/v1/address/${addressId}/update`,
      payload
    );
  };

  const waitForOrderToImport = (cartId: string, paymentIntentId: string) => {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 2500);

    return fetch(
      getCorkscrewBaseUrl() +
        `/v1/order-import/${cartId}?payment_intent=${paymentIntentId}`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((resp) => {
        if (resp.status !== 200) {
          throw new Error("not found");
        }

        return resp.json();
      })
      .then((resp) => resp.data);
  };

  return {
    client: apiClient,
    waitForOrderToImport,
    fetchOrders: (
      perPage: number,
      page: number,
      sort: string,
      before?: string,
      after?: string
    ) => {
      const queryParams = new URLSearchParams();

      queryParams.set("perPage", perPage.toString());
      queryParams.set("page", page.toString());
      queryParams.set("sort", sort.toString());

      if (before) {
        // back action
        queryParams.set("before", before);
        queryParams.set("last", perPage.toString());
      }

      if (after) {
        // next action
        queryParams.set("after", after);
        queryParams.set("first", perPage.toString());
      }

      return apiClient
        .get<OrderListResponse>(`/v1/order?${queryParams}`)
        .then((resp) => resp.data);
    },

    getProfile: () => {
      return apiClient
        .get<IPublicUserGetMeResponse & { customerId: string }>("/v1/user/me")
        .then((resp) => resp.data);
    },

    updateCustomer: (customer: any) => {
      return apiClient.post<IPublicProofAcceptResponse>(
        `/v1/customer/${customer.id}/update`,
        customer
      );
    },

    fetchMaterials: () => {
      return apiClient
        .get("/v1/materials", {
          headers: {
            "x-store-hash": process.env.NEXT_PUBLIC_STORE_HASH,
          },
        })
        .then((resp) => resp.data);
    },

    fetchOrder: (orderNumber: string): Promise<OrderGetResponse["data"]> => {
      return apiClient
        .get<any>(`/v1/order/${orderNumber}`)
        .then((resp) => resp.data.data);
    },

    approveOrder: (orderId: string) => {
      const payload: any = {
        id: orderId,
      };
      return apiClient.post<IPublicProofAcceptResponse>(
        `/v1/order/${orderId}/accept`,
        payload
      );
    },

    fetchProof: (proofId: string) => {
      return apiClient
        .get<IPublicProofGetResponse>(`/v1/proof/${proofId}`)
        .then((resp) => resp.data.data);
    },

    acceptProof: (proofId: string) => {
      const payload: IPublicProofAcceptRequest = {
        proofId,
      };
      return apiClient.post<{
        data: {
          proofApprove: {
            proof: {
              id: string;
              lineItem: { order: { allLinesApproved: boolean } };
            };
          };
        };
      }>(`/v1/proof/${proofId}/accept`, payload);
    },

    rejectProof: (proofId: string, message: string, fileId?: string) => {
      const payload: any = {
        id: proofId,
        reason: message,
        files: fileId
          ? [
              {
                id: fileId,
                // TODO: Why this?
                purposeId: PROOF_ID,
              },
            ]
          : [],
      };

      return apiClient.post<IPublicProofRejectRequest>(
        `/v1/proof/${proofId.replace("gid://glide/Proof/", "")}/reject`,
        payload
      );
    },

    fetchAddresses: async (id: string) => {
      return apiClient
        .get<IPublicAddressListResponse>(`/v1/customer/${id}`, {
          timeout: 5000,
        })
        .then((resp) => resp.data);
    },

    addAddress,
    editAddress,

    removeAddress: (id: string) => {
      return apiClient.delete(`/v1/address/${id}/delete`);
    },

    // upsertAddress: (addr: IPublicAddress) => {
    //   if (newAddressIds.includes(addr.id)) {
    //     return addAddress(addr);
    //   }

    //   return editAddress(addr);
    // },

    fetchDesigns: (perPage: number, page: number, sort = "asc") => {
      const queryParams = new URLSearchParams();
      queryParams.set("perPage", perPage.toString());
      queryParams.set("page", page.toString());
      queryParams.set("sort", sort);

      return apiClient
        .get<PublicApprovedArtworkListResponse>(
          `/v1/design?${queryParams.toString()}`
        )
        .then((resp) => {
          return resp.data;
        });
    },
    changeDesignName: (design: PublicApprovedArtwork) => {
      const payload: IPublicApprovedArtworkUpdateRequest = {
        name: design.name,
      };
      return apiClient.put<IPublicApprovedArtworkUpdateRequest>(
        `/v1/design/${design.proofId}`,
        payload
      );
    },
  };
};
