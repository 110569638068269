import ErrorIcon from "@mui/icons-material/Error";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChecklistItem } from "@/components/templates/ProofTemplate";
import { IStep } from "@/types";
import theme from "@/utils/theme";
import Bugsnag from "@bugsnag/js";
import slugify from "slugify";
import { enqueueSnackbar } from "notistack";

export const StyledReviewWrapper = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: "3px solid gold",
}));

export const ExtraLargeButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  fontSize: theme.spacing(2),
  minWidth: "300px",
}));

export const GreenAltButton = styled(ExtraLargeButton)(({ theme }) => ({
  backgroundColor: theme.colors.greenAlt,
}));

export interface IReviewProps {
  onChangeStep: (step: IStep) => void;
  proofImages: any[];
  proofImage: string;
  proofInstructions: string;
  fullSizeImageUrl?: string;
  proofChecklist: ChecklistItem[];
  checklistTitle: string;
  checklistDescription: string;
  checklistEnabled: boolean;
  hasSeenAllImages: boolean;
  approveProof: () => void;
}

interface ReviewOption {
  label: string;
  value: string;
  isChecked: boolean;
  disabled: boolean;
  description?: string;
}

const Review: React.FC<IReviewProps> = ({
  onChangeStep,
  proofImage,
  proofImages,
  proofInstructions,
  fullSizeImageUrl,
  proofChecklist,
  checklistTitle,
  checklistDescription,
  checklistEnabled,
  hasSeenAllImages,
  approveProof,
}: IReviewProps) => {
  const { t } = useTranslation("DesignApproval");

  const [isRequest, setIsRequest] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const transformedItems: ReviewOption[] = proofChecklist.map(
    (checklistItem) => {
      return {
        label: checklistItem.title,
        value: slugify(checklistItem.title),
        isChecked: false,
        disabled: false,
      };
    }
  );

  if (proofImages.length > 1) {
    transformedItems.push({
      label: "Viewed all images",
      value: "images",
      isChecked: false,
      disabled: true,
      description: "You must review all images to approve.",
    });
  }

  const [options, setOptions] = useState<ReviewOption[]>(transformedItems);

  useEffect(() => {
    if (hasSeenAllImages) {
      const updateHasSeenOption = options.map((option) => {
        if (option.value === "images") {
          return {
            ...option,
            isChecked: true,
          };
        }

        return option;
      });
      setOptions(updateHasSeenOption);
    }
  }, [hasSeenAllImages]);

  const handleCheckOption = (clickedOption: ReviewOption) => {
    const updatedOptions = options.map((item) => {
      if (clickedOption.value === item.value) {
        return {
          ...clickedOption,
          isChecked: !clickedOption.isChecked,
        };
      }

      return item;
    });

    setOptions(updatedOptions);
  };

  const handleApproveProof = async () => {
    try {
      setIsRequest(true);
      setIsSuccess(false);
      setIsFailure(false);

      await approveProof();

      setIsRequest(false);
      setIsSuccess(true);
      setIsFailure(false);
    } catch (error) {
      Bugsnag.notify(error);
      enqueueSnackbar(error.message, { variant: "error" });
      setIsRequest(false);
      setIsSuccess(false);
      setIsFailure(true);
    }
  };

  const allCheckedValues = options.map((opt) => opt.isChecked);
  const isReviewValid = checklistEnabled
    ? allCheckedValues.every((value) => value === true)
    : true;

  console.log("TEST", {
    isRequest,
    isSuccess,
    isFailure,
    isReviewValid,
  });
  return (
    <StyledReviewWrapper>
      <CardContent>
        <Box>
          <Typography variant="h4" component="div" mb={1} align="center">
            {checklistTitle}
          </Typography>
          <Typography variant="body2" component="div" mb={1} align="center">
            {checklistDescription}
          </Typography>

          {proofInstructions.length > 0 ? (
            <Box display="flex" sx={{ marginTop: 3 }}>
              <Box sx={{ marginRight: 1 }}>
                <ErrorIcon color="secondary" sx={{ marginRight: 1 }} />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <Typography>{proofInstructions}</Typography>
              </Box>
            </Box>
          ) : null}
        </Box>

        <Box
          // component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          // onClick={(event) => event?.preventDefault()}
        >
          <FormGroup>
            {options.map((option, idx) => {
              const disabledStyles = option.disabled
                ? { pointerEvents: "none", cursor: "not-allowed" }
                : {};
              return (
                <Stack direction="column" key={idx}>
                  <FormControlLabel
                    key={idx}
                    label={option.label}
                    sx={disabledStyles}
                    control={
                      <Checkbox
                        checked={option.isChecked}
                        onChange={() => handleCheckOption(option)}
                        name={option.value}
                      />
                    }
                  />
                  {option.description ? (
                    <Typography align="left" sx={{ fontSize: "14px" }}>
                      {option.description}
                    </Typography>
                  ) : null}
                </Stack>
              );
            })}
          </FormGroup>
          <Stack gap="2" spacing={{ xs: 1 }} sx={{ mt: 2 }}>
            <GreenAltButton
              disabled={isRequest || isSuccess || !isReviewValid}
              type="submit"
              variant="contained"
              color="success"
              size="large"
              onClick={handleApproveProof}
            >
              {!isRequest && !isSuccess ? "Approve" : null}
              {isRequest && !isSuccess ? "Approving..." : null}
              {!isRequest && isSuccess ? "Approved" : null}
            </GreenAltButton>
            <Typography variant="body2" component="div" mb={1} align="center">
              Or{" "}
              <Typography
                variant="body2"
                component="span"
                mb={1}
                align="center"
                onClick={() => onChangeStep("changes")}
                sx={{ color: theme.colors.coolBlue, cursor: "pointer" }}
              >
                {t("review.requestChanges")}
              </Typography>
            </Typography>
          </Stack>
        </Box>
      </CardContent>
    </StyledReviewWrapper>
  );
};

export default Review;
