import { OrderListEdge } from "@/api/corkcrew";
import theme from "@/utils/theme";
import { useTranslation } from "next-i18next";
import React from "react";
import { NoDataFound, OrderListItem } from "../atoms";
import {
  Box,
  Button,
  Dialog,
  Divider,
  ImageList,
  Typography,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Stack,
} from "@mui/material";
import Link from "next/link";
import { id } from "@/utils/gid";
import LaunchIcon from "@mui/icons-material/Launch";
import { formatUtcDate } from "@/utils/date";

interface IProps {
  orders: OrderListEdge[];
  ordersPagePath: string;
  canDownloadInvoice: boolean;
}

const OrderListTable: React.FC<IProps> = ({
  orders,
  ordersPagePath,
  canDownloadInvoice,
}: IProps) => {
  const { t, ready } = useTranslation("OrderList");
  const { t: noDataErrors, ready: nReady } =
    useTranslation("NoDataFoundErrors");

  if (orders && orders.length === 0) {
    return <NoDataFound defaultStyles message={noDataErrors("noOrders")} />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("orderNumber")}</TableCell>
            <TableCell align="right">{t("orderDate")}</TableCell>
            <TableCell align="right">{t("status")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => {
            return (
              <TableRow
                key={order.node.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link href={`${ordersPagePath}/${id(order.node.id)}`}>
                    <Button variant="outlined">
                      {order.node.name}
                      <LaunchIcon sx={{ width: 16, heigh: 16, ml: 0.5 }} />
                    </Button>
                  </Link>
                </TableCell>
                <TableCell align="right">
                  {formatUtcDate(
                    new Date(order.node.createdAt as unknown as string)
                  )}
                </TableCell>
                <TableCell align="right">
                  {order.node.proofStatus?.name || "-"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderListTable;
