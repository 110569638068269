import React, { useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getSafePercent, getStepPosition } from "./progress";
import { OrderProgressStep, OrderProgressStepType } from "./types";
import { styled } from "@mui/material/styles";
import theme from "@/utils/theme";
import { Bar, Card, CardContent, ProgressBar } from "./OrderProgress.styles";
import {
  CardActions,
  Collapse,
  IconButton,
  IconButtonProps,
  useMediaQuery,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const defaultSteps: OrderProgressStep[] = [
  {
    key: OrderProgressStepType.OrderPlaced,
    title: "Order Placed",
    description: "Your order has been placed",
    icon: "orderPlaced",
  },
  {
    key: OrderProgressStepType.DesignInProgress,
    active: true,
    title: "Design in progress",
    description: "Your design is being edited",
    modal: {
      title: "Design in progress",
      description:
        "Our design team has received your artwork and are preparing a design proof. We complete various checks to ensure your design prints perfectly.",
    },
    icon: "designInProgress",
  },
  {
    key: OrderProgressStepType.FeedbackRequired,
    title: "Feedback required",
    description: "Please provide feedback about your proofs",
    modal: {
      title: "Feedback required",
      description:
        "Please review your design proof. If you are happy, click approve, and we will start printing. If you would like changes, request them for free until your design is perfect.",
    },
    icon: "feedbackRequired",
  },
  {
    key: OrderProgressStepType.Printing,
    title: "Printing",
    description: "Your order is being printed",
    modal: {
      title: "Printing",
      description:
        "We have started to print your order using our latest digital printers that have the best colours & highest resolution",
    },
    icon: "printing",
  },
  {
    key: OrderProgressStepType.Cutting,
    title: "Cutting",
    description: "Your order is being cut",
    modal: {
      title: "Cutting",
      description:
        "We are in the process of cutting your order with our digitally controlled cutters that are accurate to 0.1mm and can achieve the finest details.",
    },
    icon: "cutting",
  },
  {
    key: OrderProgressStepType.QualityControl,
    title: "Quality Control",
    description: "We're checking your order to ensure it's printed correctly",
    modal: {
      title: "Quality Control",
      description:
        "Before we ship your order, we check every little detail, from the print quality to the cut accuracy. Once it passes our rigorous tests, we will package your order ready for shipping.",
    },
    icon: "qualityControl",
  },
  {
    key: OrderProgressStepType.Shipping,
    title: "Shipping",
    description: "Your order is being shipped",
    modal: {
      title: "Shipping",
      description:
        "We have shipped your order. We have sent you an email with a link to track your parcel",
    },
    icon: "shipping",
  },
];

interface IStyleProps {
  barMargin: string;
  progressBarWidth: number;
}

export interface IOrderProgressProps {
  steps: OrderProgressStep[];
}

export const OrderProgress = (props: IOrderProgressProps) => {
  const [modalStep, setModalStep] = useState<OrderProgressStep | null>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState(!isSmallScreen);

  const curStep = props.steps.find((step) => step.active);

  const INACTIVE_ICON_SIZE = 34;

  const safePercent = getSafePercent(
    getStepPosition(
      props.steps.length,
      props.steps.findIndex((step) => step.active),
      true
    )
  );

  const handleOpen = (step: OrderProgressStep) => {
    setModalStep(step);
  };

  const handleClose = () => {
    setModalStep(null);
  };

  const progressStyleProps: IStyleProps = {
    barMargin: `0 ${INACTIVE_ICON_SIZE / 2}px`,
    progressBarWidth: safePercent,
  };

  if (!curStep) {
    return null;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const stepWidth = 100 / props.steps.length;

  return (
    <Box>
      {curStep.active ? (
        <Card sx={{ minWidth: 275 }}>
          <Bar>
            <ProgressBar
              sx={{
                width: `${progressStyleProps.progressBarWidth}%`,
              }}
            />
          </Bar>

          <CardContent
            sx={{ position: "relative", paddingBottom: "0 !important" }}
          >
            <Typography variant="h5" component="div">
              {curStep.title}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              This is step{" "}
              {props.steps.findIndex((step) => curStep.key === step.key) + 1}/
              {props.steps.length}
            </Typography>
            <Typography variant="body2">{curStep.description}</Typography>
          </CardContent>
          <CardActions sx={{ margin: 0 }}>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Box sx={{}}>
                <Box
                  sx={{
                    position: "relative",
                    paddingLeft: { xs: "25px" },
                    borderLeft: "1px solid",
                    borderColor: theme.colors.coolBlueLight,
                    marginLeft: { xs: "10px" },
                  }}
                >
                  {props.steps.map((step, index) => {
                    const activeStyling = step.active
                      ? {
                          backgroundColor: theme.colors.white,
                          border: "4px solid",
                          borderColor: theme.colors.coolBlueDark,
                        }
                      : {};

                    return (
                      <Box
                        key={index}
                        sx={{
                          mb: 2,
                          "&:before": {
                            content: `""`,
                            position: "absolute",
                            left: -10,
                            display: "inline-block",
                            height: "20px",
                            width: "20px",
                            borderRadius: "20px",
                            backgroundColor: theme.colors.coolBlueLight,
                            ...activeStyling,
                          },
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ lineHeight: 1 }}
                        >
                          {step.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {step.description}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </CardContent>
          </Collapse>
        </Card>
      ) : null}
    </Box>
  );
};
