import {
  getSteps,
  statusToStep,
} from "@/components/templates/OrderViewTemplate";
import { ChecklistItem } from "@/components/templates/ProofTemplate";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import { IPublicOrderExt, IStep, ProofStatues } from "@/types";
import { allProofsAccepted, findNextProofId } from "@/utils/proof-helpers";
import Bugsnag from "@bugsnag/js";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { useParams } from "next/navigation";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useTranslation } from "react-i18next";
import { ReviewDesign } from "../../atoms";
import Changes from "./Changes";
import CountdownModal from "./CountdownModal";
import Printing from "./Printing";
import Review from "./Review";
import StartProduction from "./StartProduction";
import type { IRejectFormData } from "./types";
// import { mockApiCall } from "@/utils/browser";

interface IDesignApprovalProps {
  orderNumber: string;
  proofId: string;
  currentStep: ProofStatues;
  proofImage: string;
  proofInstructions: string;
  fullSizeImageUrl?: string;
  proofChecklist: ChecklistItem[];
  checklistTitle: string;
  checklistDescription: string;
  checklistEnabled: boolean;
  hasSeenAllImages: boolean;
  proofImages: any[];
}

const CONFETTI_TIMER = 3000;

const DesignApproval: React.FC<IDesignApprovalProps> = (
  props: IDesignApprovalProps
) => {
  const params = useParams();
  const router = useRouter();
  const corkscrewApi = useCorkscrewApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t: tSteps } = useTranslation("ProgressSteps", {});
  const { t: tDesignApproval } = useTranslation("DesignApproval");

  const orderNumber = params?.id as string;
  const proofId = params?.proofId as string;

  const [showConfetti, setShowConfetti] = useState(false);
  const [openProductionModal, setOpenProductionModal] = useState(false);
  const [order, setOrder] = useState<IPublicOrderExt>();
  const [currentStep, setCurrentStep] = useState<IStep>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const allApproved = allProofsAccepted(order?.lineItems);
  const steps = getSteps(tSteps, statusToStep(order));
  const activeStep = steps.find((step) => step.active);

  const isPrinting = activeStep?.key === "Printing";

  const fetchNewOrder = async () => {
    if (!params || !corkscrewApi || !proofId) {
      return;
    }

    try {
      const order = await corkscrewApi?.fetchOrder(orderNumber);
      if (!order) {
        return;
      }

      setOrder(order);
      return order;
    } catch (error) {
      enqueueSnackbar("Could not fetch new order", { variant: "error" });
      Bugsnag.notify(error);
    }
  };

  useEffect(() => {
    // Need to fetch the order so we can swap to start-production
    if (!order) {
      fetchNewOrder();
    }

    if (allApproved && isPrinting) {
      setCurrentStep("printing");
      return;
    }
  }, [order]);

  const handleOnDismiss = () => {
    setOpenProductionModal(false);
  };

  const handleSendToProduction = async () => {
    return new Promise(async (resolve, reject) => {
      if (!corkscrewApi) {
        reject("No API found");
        return;
      }

      // FOR TESTING
      // const response = await mockApiCall();
      // handleConfetti();
      // resolve("Success");
      // setTimeout(() => {
      //   setOpenProductionModal(false);
      //   router.replace(`/orders/${router.query.id}`);
      // }, CONFETTI_TIMER);
      // return;

      try {
        const resp: any = await corkscrewApi.approveOrder(
          orderNumber as string
        );

        if (resp.data.errors && resp.data.errors.length > 0) {
          setOpenProductionModal(false);
          throw new Error(resp.data.errors[0].message);
        }

        handleConfetti();
        resolve("Success");
        setOpenProductionModal(false);
        router.replace(`/orders/${router.query.id}`);
      } catch (error) {
        Bugsnag.notify(error);
        enqueueSnackbar(
          "Could not send to production, please try again or contact us",
          { variant: "error" }
        );
        reject(error);
      }
    });
  };

  const handleAcceptProof = async () => {
    return new Promise(async (resolve, reject) => {
      if (!corkscrewApi) {
        reject("No API found");
        return;
      }

      // FOR TESTING
      // const response = await mockApiCall();
      // setCurrentStep("start-production");
      // // This triggers the redirect
      // setOpenProductionModal(true);
      // resolve("Success");
      // return;

      try {
        const resp = await corkscrewApi.acceptProof(
          props.proofId.replace("gid://glide/Proof/", "")
        );

        const updatedOrder = await fetchNewOrder();

        setIsSubmitting(false);

        // 1 proof => approve the proof => show the start production screen
        // 2+ proofs => approve each proof => redirect back to the orders/{id} page which has the banner so they can then click the "start production" button?

        // const allApproved = allProofsAccepted(updatedOrder?.lineItems);
        const nextProofId = findNextProofId(updatedOrder, proofId);

        if (nextProofId) {
          router.replace(`/orders/${router.query.id}/proofs/${nextProofId}`);
          return;
        }

        if (resp.data.data.proofApprove.proof.lineItem.order.allLinesApproved) {
          setCurrentStep("start-production");
          // This triggers the redirect
          setOpenProductionModal(true);
          resolve("Success");
        } else {
          router.replace(`/orders/${router.query.id}`);
        }
      } catch (error) {
        Bugsnag.notify(error);
        setIsSubmitting(false);
        enqueueSnackbar(
          "Could not mark proof as Approved, please try again or contact us",
          { variant: "error" }
        );
        reject(error);
      }
    });
  };

  const handleConfetti = () => {
    setShowConfetti(true); // Show confetti

    // Hide confetti after 3 seconds
    setTimeout(() => {
      setShowConfetti(false);
    }, CONFETTI_TIMER);
  };

  const handleFeedbackSubmit = (feedback: IRejectFormData) => {
    if (!corkscrewApi) {
      return;
    }

    corkscrewApi
      .rejectProof(props.proofId, feedback.feedback, feedback.fileId)
      .then(fetchNewOrder)
      .then((updatedOrder) => {
        setIsSubmitting(false);
        enqueueSnackbar("Feedback sent!", { variant: "success" });

        const nextProofId = findNextProofId(updatedOrder, proofId);

        if (nextProofId) {
          router.replace(`/orders/${router.query.id}/proofs/${nextProofId}`);
          return;
        }

        router.replace(`/orders/${router.query.id}`);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setIsSubmitting(false);

        enqueueSnackbar(
          "Could not mark proof as Approved, please try again or contact us",
          { variant: "error" }
        );
      });
  };

  const handleStartProduction = () => {
    if (!corkscrewApi || !orderNumber) {
      return;
    }

    corkscrewApi
      .approveOrder(orderNumber as string)
      .then(() => {
        corkscrewApi
          .fetchOrder(orderNumber.toString() || "")
          .then((order) => {
            if (!order) {
              return;
            }

            router.replace(`/orders/${router.query.id}`);
          })
          .catch((err) => {
            Bugsnag.notify(err);
          });
      })
      .catch((err) => {
        Bugsnag.notify(err);
        // TODO: Add toast to
      });
  };

  const renderStep = (proofStatus: ProofStatues, currentStep?: IStep) => {
    if (currentStep === "start-production") {
      return (
        <StartProduction
          disabled={isSubmitting}
          onStartProductionClick={handleStartProduction}
          onChangeStep={(step: IStep) => setCurrentStep(step)}
          proofImage={props.proofImage}
        />
      );
    } else if (currentStep === "changes") {
      return (
        <Changes
          disabled={isSubmitting}
          onSubmitChanges={handleFeedbackSubmit}
          onChangeStep={(step: IStep) => setCurrentStep(step)}
          proofImage={props.proofImage}
        />
      );
    } else if (currentStep === "printing") {
      return <Printing />;
    }

    switch (proofStatus) {
      case "sent":
        return (
          <Review
            proofImages={props.proofImages}
            proofImage={props.proofImage}
            onChangeStep={(step: IStep) => setCurrentStep(step)}
            proofInstructions={props.proofInstructions}
            fullSizeImageUrl={props.fullSizeImageUrl}
            proofChecklist={props.proofChecklist}
            checklistTitle={props.checklistTitle}
            checklistDescription={props.checklistDescription}
            checklistEnabled={props.checklistEnabled}
            hasSeenAllImages={props.hasSeenAllImages}
            approveProof={handleAcceptProof}
          />
        );
      case "approved":
        return (
          <Card>
            <CardContent>
              <Stack gap={2}>
                <Typography variant="h4" component="div" align="center">
                  {tDesignApproval("approvedDesign.approved")}
                </Typography>
                <Typography variant="body2" component="div" align="center">
                  {tDesignApproval("approvedDesign.title")}
                </Typography>
                <Typography variant="body2" component="div" align="center">
                  {tDesignApproval("approvedDesign.message")}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        );
      case "rejected":
        return (
          <Card>
            <CardContent>
              <Stack gap={2}>
                <Typography variant="h4" component="div" align="center">
                  {tDesignApproval("rejected.title")}
                </Typography>
                <Typography variant="body2" component="div" align="center">
                  {tDesignApproval("rejected.message")}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        );
      case "approved":
        return (
          <ReviewDesign
            proofImage={props.proofImage}
            allApproved={allApproved}
          />
        );
      default:
        return (
          <Card>
            <CardContent>
              <Stack gap={2}>
                <Typography variant="h4" component="div" align="center">
                  Design in progress
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <>
      {showConfetti && (
        <ConfettiExplosion
          particleCount={300}
          duration={3000}
          force={0.8}
          zIndex={1400}
        />
      )}

      {openProductionModal ? (
        <CountdownModal
          isOpen={openProductionModal}
          onComplete={handleSendToProduction}
          onDismiss={handleOnDismiss}
        />
      ) : null}

      {renderStep(props.currentStep, currentStep)}
    </>
  );
};

export default DesignApproval;
