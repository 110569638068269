import Box from "@mui/material/Box";
import { useTranslation } from "next-i18next";
import { useState } from "react";

import { MailingAddressInput } from "@/types";
import theme from "@/utils/theme";
import ConfirmationDialog from "../ConfirmationDialog";
import { ActionsTypography, Separator, UserCard } from "./style";
import { TableCell, TableRow } from "@mui/material";

interface IProps {
  address: MailingAddressInput;
  handleRemove: (address: MailingAddressInput) => void;
  handleEdit: (address: MailingAddressInput) => void;
  handleSetDefault: (address: MailingAddressInput) => void;
}

const UserAddressCard = ({
  address,
  handleRemove,
  handleEdit,
  handleSetDefault,
}: IProps) => {
  const { t } = useTranslation("UserAddressCard");
  const [type, setType] = useState<"delete" | "default">("delete");
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const handleModalToggle = () =>
    setOpenConfirmationDialog(!openConfirmationDialog);

  return (
    <TableRow>
      <TableCell>
        {address.firstName} {address.lastName}
      </TableCell>
      <TableCell align="right">{address.address1 || "-"}</TableCell>
      <TableCell align="right">{address.address2 || "-"}</TableCell>
      <TableCell align="right">{address.city || "-"}</TableCell>
      <TableCell align="right">{address.zip || "-"}</TableCell>
      <TableCell align="right">{address.phone || "-"}</TableCell>
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "0 6px",
          }}
        >
          <ActionsTypography onClick={() => handleEdit(address)}>
            {t("edit")}
          </ActionsTypography>
          <Separator />
          <ActionsTypography
            onClick={() => {
              handleModalToggle();
              setType("delete");
            }}
          >
            {t("remove")}
          </ActionsTypography>

          {/* {address.isDefault ? (
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography sx={{ fontSize: "14px" }}>
              {t("defaultAddress")}
            </Typography>
            <CheckCircleIcon sx={{ width: 16, height: 16 }} />
          </Box>
        ) : (
          <ActionsTypography
            onClick={() => {
              handleModalToggle();
              setType("default");
            }}
          >
            {t("setDefault")}
          </ActionsTypography>
        )} */}
        </Box>
      </TableCell>
      <ConfirmationDialog
        handleClose={handleModalToggle}
        handleDelete={() => {
          if (type === "delete") {
            handleRemove(address);
            handleModalToggle();
          } else {
            handleSetDefault(address);
            handleModalToggle();
          }
        }}
        title={type === "delete" ? t("deleteAddress") : t("setDefault")}
        open={openConfirmationDialog}
      >
        {type === "delete" ? (
          <>{t("confirmDeleteAddress")}</>
        ) : (
          <>{t("confirmDefaultAddress")}</>
        )}
      </ConfirmationDialog>
    </TableRow>
  );
};

export default UserAddressCard;
