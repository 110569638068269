"use client";

import { useEffect, useState } from "react";
import GlideLogo from "../../../public/assets/svgs/glide-logo.svg";
import { useUser } from "@/contexts/userContext";

const Logo = (props: {}) => {
  const {
    state: { logoHorizontal },
  } = useUser();
  const [logoUrl, setLogoUrl] = useState(GlideLogo);

  useEffect(() => {
    if (logoHorizontal?.url) {
      setLogoUrl({ src: `${logoHorizontal?.url}?width=250&height=30` });
    }
  }, [logoHorizontal?.url]);

  return <img width={100} height={30} src={logoUrl.src} alt="Brand logo" />;
};

export default Logo;
