import Bugsnag from "@bugsnag/js";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  isOpen: boolean;
  onComplete: () => void;
  onDismiss: () => void;
};

export default function CountdownModal(props: Props) {
  const [status, setStatus] = useState<null | "pending" | "success" | "error">(
    null
  );

  const handleAcceptClick = async () => {
    setStatus("pending");

    try {
      await props.onComplete();
      setStatus("success");
    } catch (error) {
      console.log("error", error);
      Bugsnag.notify(error);
      setStatus("error");
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <h1>
            {status === "error" ? "An error occurred" : "Ready to Print!"}
          </h1>
        </DialogTitle>

        <DialogContent>
          {status === "error" ? (
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              Uh oh! Something went wrong approving your order. Please contact
              support and we&apos;ll sort it out
            </DialogContentText>
          ) : (
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ textAlign: "center" }}
            >
              Everything looks good from our side! You&apos;ve approved all
              designs and we&apos;d like to start printing your order.
            </DialogContentText>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", paddingBottom: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleAcceptClick}
              disabled={status === "pending" || status === "error"}
              size="large"
            >
              {status === "pending" ? "Sending..." : "Start Printing"}
            </Button>
            <Button
              variant="text"
              color="warning"
              href="https://stickerit.co/contact?utm_source=myaccount&utm_campaign=approveError"
              size="small"
              sx={{ fontSize: 10, textTransform: "initial" }}
            >
              {status === "error" ? "Contact Support" : "Or Contact Support"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
