import { OrderListEdge, PageInfo } from "@/api/corkcrew";
import errorValidator from "@/api/errorValidator";
import { useUser } from "@/contexts/userContext";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import {
  EModalActions,
  IPayment,
  MailingAddressInput,
  MaterialType,
  PublicApprovedArtwork,
} from "@/types";
import routes from "@/utils/routes";
import theme from "@/utils/theme";
import type { IPublicUserGetMeResponse } from "@kartdavid/corkscrew-types/packages/api-types/public-api/api_types_public_api";
import {
  Box,
  Button,
  Dialog,
  Divider,
  ImageList,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "next-i18next";
import { default as NextLink } from "next/link";
import React, { Fragment, useEffect, useState } from "react";
import { LoadingView, UserAddressCard, UserPaymentCard } from "../atoms";
import { OrderListTable } from "../molecules";
import DesignItem from "./DesignsView/DesignItem";
// import AddAddressModal from "../molecules/Modals/AddAddressModal";
import { getCorkscrewBaseUrl } from "@/utils/config";
import { decode } from "@/utils/gid";
import Bugsnag from "@bugsnag/js";
import { useRouter } from "next/router";
import EditCustomerModal from "./EditCustomerModal";

/* Styling */
const Wrapper = styled("div")(() => ({
  marginBottom: theme.spacing(2),
}));

const SectionHeaderTypography = styled(Typography)(() => ({
  fontSize: theme.typography.pxToRem(26),
  marginBottom: theme.spacing(1),
  fontWeight: 500,
}));

const RestyledButton = styled(Button)(() => ({
  marginTop: theme.spacing(2),
  border: `2px solid ${theme.colors.coolBlue}`,
  ":hover": {
    border: `2px solid ${theme.colors.coolBlue}`,
  },
  fontWeight: theme.typography.fontWeightRegular,
  minWidth: 150,
}));

/* End of styling */

export type MyDetailsProps = {
  allMaterials: { data: MaterialType[] };
  hasMoreOrders: boolean;
  designs: PublicApprovedArtwork[];
  userPaymentMethods: IPayment[];
  userAddressesState: MailingAddressInput[];
  userActions: {
    addAddress: (
      address: MailingAddressInput,
      handleActionChange: () => void
    ) => void;
    removeAddress: (address: MailingAddressInput) => void;
    editAddress: (
      address: MailingAddressInput,
      handleActionChange: () => void
    ) => void;
    setDefaultAddress: (address: MailingAddressInput) => void;
  };
  handleEditButtonClick: (addr: MailingAddressInput) => void;
  handleClick: (modalAction: EModalActions) => void;
  addresses: any[];
};

const features = {
  payments: false,
  designs: true,
  preferences: false,
  addresses: true,
};

const defaultItemPerPage = 10;

const MyDetails: React.FC<MyDetailsProps> = ({
  allMaterials,
  hasMoreOrders,
  userAddressesState,
  designs,
  userPaymentMethods,
  userActions,
  handleEditButtonClick,
  handleClick,
  addresses,
}) => {
  const router = useRouter();
  const params = router.query;
  const corkscrewApiUrl = getCorkscrewBaseUrl();
  const corkscrewApi = useCorkscrewApi();
  const { t, ready } = useTranslation("AccountSummary");
  const { t: tAddressList } = useTranslation("AddressList");
  const {
    state: { profile, canDownloadInvoice, id },
    dispatch,
  } = useUser();

  const [orders, setOrders] = useState<OrderListEdge[]>([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [isFetchingOrders, setIsFetchingOrders] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemPerPage);

  const [showEditMyDetailsModal, setShowEditMyDetailsModal] = useState(false);

  const [myDetails, setMyDetails] = useState<
    IPublicUserGetMeResponse | undefined
  >(undefined);
  const ordersPagePath = routes.orders;

  useEffect(() => {
    if (!profile && !ready) return;

    setMyDetails(profile);
  }, [profile, ready]);

  useEffect(() => {
    if (!corkscrewApi) {
      return;
    }

    handleFetchOrders(itemsPerPage, page, "desc");
  }, [corkscrewApi, itemsPerPage]);

  const handleFetchOrders = (
    perPage: number = itemsPerPage,
    page: number,
    sort: string,
    before?: string,
    after?: string
  ) => {
    corkscrewApi
      .fetchOrders(perPage, page, sort, before, after)
      .then((fetchedOrders) => {
        const sortedOrders = (fetchedOrders?.edges || []).sort((a, b) =>
          b.node.id.localeCompare(a.node.id)
        );

        setOrders(sortedOrders);
        setPageInfo(fetchedOrders.pageInfo);
        setIsFetchingOrders(false);
        setTotalCount(fetchedOrders.totalCount);
      })
      .catch((err) => errorValidator(err));
  };

  const handleCloseMyDetailsModal = () => {
    setShowEditMyDetailsModal(false);
  };

  const handleSaveMyDetailsUpdate = async (address: MailingAddressInput) => {
    if (!address.id) {
      return;
    }

    const updatedAddress = {
      id: decode(address.id).id,
      companyName: address.companyName || "",
      email: address.email,
      firstName: address.firstName,
      lastName: address.lastName,
      phone: address.phone,
      brandId: "gid://glide/BrandId/",
    };

    try {
      const resp: any = await corkscrewApi.updateCustomer(updatedAddress);
      dispatch({
        type: "setProfile",
        profile: resp.data.customerUpdate.customer,
      });
      setMyDetails(resp.data.customerUpdate.customer);
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // TODO: don't love this.
    if (newPage > page) {
      // next action
      handleFetchOrders(
        itemsPerPage,
        newPage,
        "desc",
        undefined,
        pageInfo?.endCursor
      );
      setPage(newPage);
      return;
    }

    // back action
    handleFetchOrders(
      itemsPerPage,
      newPage,
      "desc",
      pageInfo?.startCursor,
      undefined
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Fragment>
      {myDetails ? (
        <Stack spacing={4}>
          <Dialog open={showEditMyDetailsModal}>Edit my details modal</Dialog>

          {myDetails.firstName ? (
            <Typography variant="h1">Welcome {myDetails.firstName}!</Typography>
          ) : null}

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography variant="h3">{t("meta.title")}</Typography>
          </Stack>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Full name</TableCell>
                  <TableCell align="right">Phone</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Company name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {myDetails.firstName} {myDetails.lastName}
                  </TableCell>
                  <TableCell align="right">{myDetails.phone}</TableCell>
                  <TableCell align="right">{myDetails.email}</TableCell>
                  <TableCell align="right">{myDetails.companyName}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setShowEditMyDetailsModal(true)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <EditCustomerModal
            edit
            handleClose={handleCloseMyDetailsModal}
            open={showEditMyDetailsModal}
            handleSubmit={handleSaveMyDetailsUpdate}
            addressToEdit={{ ...myDetails }}
          />

          <Box>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Typography variant="h3">{t("titles.latestOrders")}</Typography>
            </Stack>

            {isFetchingOrders === true ? <LoadingView /> : null}

            {isFetchingOrders === false ? (
              <>
                <OrderListTable
                  orders={orders}
                  ordersPagePath={ordersPagePath}
                  canDownloadInvoice={canDownloadInvoice}
                />
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={itemsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : null}
            {hasMoreOrders ? (
              <Box>
                <NextLink href={ordersPagePath}>
                  <RestyledButton variant="outlined">
                    {t("buttons.seeMore")}
                  </RestyledButton>
                </NextLink>
              </Box>
            ) : null}
          </Box>
          <Box>
            {!features.addresses ? null : (
              <>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Typography variant="h3">{t("titles.address")}</Typography>

                  <div>
                    <Button
                      variant="outlined"
                      onClick={() => handleClick(EModalActions.addAddress)}
                    >
                      {t("buttons.addAddress")}
                    </Button>
                  </div>
                </Stack>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Address</TableCell>
                        <TableCell align="right">Address 2</TableCell>
                        <TableCell align="right">City</TableCell>
                        <TableCell align="right">Zip code</TableCell>
                        <TableCell align="right">Phone</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addresses &&
                        addresses.length > 0 &&
                        addresses.map((address, index: number) => (
                          <UserAddressCard
                            key={index}
                            address={address}
                            handleRemove={() =>
                              userActions.removeAddress(address)
                            }
                            handleEdit={handleEditButtonClick}
                            handleSetDefault={userActions.setDefaultAddress}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            {features.payments ? (
              <Fragment>
                <Wrapper>
                  <SectionHeaderTypography>
                    {t("titles.paymentMethods")}
                  </SectionHeaderTypography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {userPaymentMethods.map((paymentMethod, index: number) => (
                      <UserPaymentCard
                        key={index}
                        payment={paymentMethod}
                        handleRemove={() => {}}
                        handleEdit={() => null}
                        handleSetDefault={() => null}
                      />
                    ))}
                  </Box>
                  <div>
                    <RestyledButton
                      variant="outlined"
                      onClick={() =>
                        handleClick(EModalActions.addPaymentMethod)
                      }
                    >
                      {t("buttons.addPaymentMethod")}
                    </RestyledButton>
                  </div>
                </Wrapper>
              </Fragment>
            ) : null}

            {features.designs && designs.length > 0 ? (
              <Box sx={{ mt: 3 }}>
                <Wrapper>
                  <SectionHeaderTypography>
                    {t("titles.designs")}
                  </SectionHeaderTypography>
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <ImageList cols={5} gap={12}>
                      {designs.map((design, idx) => {
                        return (
                          <DesignItem
                            key={design.proofId || idx}
                            design={design}
                            allMaterials={allMaterials}
                          />
                        );
                      })}
                    </ImageList>
                  </Box>
                  <Box sx={{ display: { xs: "block", md: "none" } }}>
                    <ImageList cols={2} gap={12}>
                      {designs.map((design, idx) => {
                        return (
                          <DesignItem
                            key={design.proofId || idx}
                            design={design}
                            allMaterials={allMaterials}
                          />
                        );
                      })}
                    </ImageList>
                  </Box>
                  {designs.length > 1 ? (
                    <Box>
                      <NextLink href="/designs">
                        <RestyledButton variant="outlined">
                          {t("buttons.seeMore")}
                        </RestyledButton>
                      </NextLink>
                    </Box>
                  ) : null}
                </Wrapper>
              </Box>
            ) : null}

            {features.preferences ? (
              <Fragment>
                <Divider sx={{ background: theme.colors.greyLight }} />
                <Box
                  sx={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(10),
                  }}
                >
                  <SectionHeaderTypography>
                    {t("titles.preferences")}
                  </SectionHeaderTypography>
                  <div>
                    {/* {preferenceItems.map((item) => (
        <PreferenceItem
          key={item.id}
          item={item}
          handleClick={() => {
            handleClick(EModalActions.preferenceLanguage);
            handleEditOrder(item.id);
          }}
          setFormData={setPreferencesFormData}
        />
      ))} */}
                  </div>
                </Box>
              </Fragment>
            ) : null}
          </Box>
        </Stack>
      ) : (
        <LoadingView />
      )}
    </Fragment>
  );
};

export default MyDetails;
