import { uiState } from "@/contexts/uiState";
import { useUser } from "@/contexts/userContext";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import { IPublicOrderExt } from "@/types";
import { decode } from "@/utils/gid";
import { getAllowedFiles } from "@/utils/proof-helpers";
import routes from "@/utils/routes";
import { getContrastColor } from "@/utils/theme";
import type { IPublicProof } from "@kartdavid/corkscrew-types/public";
import { InfoOutlined } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StraightenIcon from "@mui/icons-material/Straighten";
import ImageIcon from "@mui/icons-material/Image";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { t } from "i18next";
import Head from "next/head";
import { default as NextLink } from "next/link";
import { NextRouter, useRouter } from "next/router";
import React, {
  ComponentType,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilState } from "recoil";
import { LoadingView } from "../atoms";
import { DesignApproval } from "../molecules";
import ProofImageCarousel from "../molecules/DesignApproval/ProofImageCarousel";
import Metafield from "../molecules/Metafield";
import SectionProofImage from "../molecules/DesignApproval/SectionProofImage";

import dynamic from "next/dynamic";

const mapped: Record<
  string,
  ComponentType<{ itemRef: any; lineItemName: string }>
> = {
  HistoryLineCreated: dynamic(
    () => import("./history-items/HistoryItemOrderCreated")
  ),
  HistoryLineProofCreated: dynamic(
    () => import("./history-items/HistoryItemProofCreated")
  ),
  HistoryItemUploaded: dynamic(
    () => import("./history-items/HistoryItemUploaded")
  ),
  HistoryLineCustomerArtworkAdded: dynamic(
    () => import("./history-items/HistoryItemArtworkAdded")
  ),
  HistoryLineProofAccepted: dynamic(
    () => import("./history-items/HistoryItemProofAccepted")
  ),
  HistoryLineProofRejected: dynamic(
    () => import("./history-items/HistoryItemRejected")
  ),
};

interface IBackground {
  id;
  name;
  isCheckered;
  color1Name;
  color1HtmlColor;
  color2Name;
  color2HtmlColor;
  color3Name;
  color3HtmlColor;
  color4Name;
  color4HtmlColor;
  isBuiltin;
}

export interface ChecklistItem {
  id: string;
  title: string;
  description: string;
  position: number;
}

interface ITemplate {
  id: string;
  name: string;
  checklistTitle: string;
  checklistDescription: string;
  checklistEnabled: boolean;
  checklistItems: ChecklistItem[];
  filePurposes: { id: string; name: string }[];
  keys: {
    name: string;
    description: string;
    strokeColor: string;
    fillColor: string;
  }[];
}
export interface ProofWithTemplate extends IPublicProof {
  files: any;
  version: number;
  template: ITemplate;
  designComments: string;
  templateBackground: IBackground;
}

const ProofTemplate: React.FC = () => {
  const {
    state: { shopifyOrderUrl },
  } = useUser();
  const corkscrewApi = useCorkscrewApi();
  const router: NextRouter = useRouter();
  const [ui, setState] = useRecoilState(uiState);

  const defaultColor = "inherit";
  const dynamicColor = ui.isLight ? ui.color1 : ui.color2;
  const textColor = ui.isCheckered
    ? defaultColor
    : getContrastColor(dynamicColor);

  const [hasSeenAllImages, setHasSeenAllImages] = useState(false);
  const [order, setOrder] = useState<IPublicOrderExt | null>(null);
  const [lineItem, setLineItem] = useState<any>(null);
  const [proof, setProof] = useState<ProofWithTemplate | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const orderNumber = router.query?.id;
  const proofId = router.query?.proofId;

  const bgColor = ui.isCheckered
    ? "inherit"
    : ui.isLight
      ? ui.color1
      : ui.color2;

  useEffect(() => {
    if (!corkscrewApi || !orderNumber || !proofId) {
      return;
    }

    setIsLoading(true);
    setHasSeenAllImages(false);

    const fetchOrderPromise = corkscrewApi
      ?.fetchOrder(orderNumber?.toString() || "")
      .then((order) => {
        if (!order) {
          return;
        }
        setOrder(order);
        const li = order.lineItems.find((li) =>
          li.proofs.find((p) => p.id.includes(proofId))
        );
        setLineItem(li);
        setProof(li?.proofs?.at(0));
      });

    Promise.all([fetchOrderPromise]).then(() => {
      setIsLoading(false);
    });
  }, [orderNumber, proofId, corkscrewApi]);

  useEffect(() => {
    if (proof && proof.templateBackground) {
      setState((prevState) => {
        return {
          ...prevState,
          isCheckered: proof.templateBackground.isCheckered,
          color1: proof.templateBackground.color1HtmlColor,
          color2: proof.templateBackground.color2HtmlColor,
          color3: proof.templateBackground.color3HtmlColor,
          color4: proof.templateBackground.color4HtmlColor,
        };
      });
    }
  }, [proof]);

  useEffect(() => {
    if (proof && order && lineItem) {
      setState((prevState) => ({
        ...prevState,
        proofPagination: {
          ...prevState.proofPagination,
          lineItems: order.lineItems.filter((lineItem) => {
            return Array.isArray(lineItem.proofs) && lineItem.proofs.length > 0;
          }),
          proofId: proof.id,
          proofName: lineItem.name,
        },
      }));
    }
  }, [proof, order, lineItem]);

  const sortedProofChecklist = useMemo(() => {
    if (!proof) {
      return [];
    }

    return (proof?.template.checklistItems || []).slice().sort((a, b) => {
      return a.position - b.position;
    });
  }, [proof]);

  if (isLoading) {
    return (
      <Box sx={{ py: 4, backgroundColor: bgColor }}>
        <Container>
          <LoadingView suffix="Proof" />
        </Container>
      </Box>
    );
  }

  if (!order) {
    return (
      <Box sx={{ py: 4, backgroundColor: bgColor }}>
        <Container>
          <Alert severity="error">
            <Stack>
              <Typography>{t("orderNotFound")}</Typography>
              <Link
                component={NextLink}
                underline="hover"
                href={`${routes.orders}`}
              >
                Back to orders
              </Link>
            </Stack>
          </Alert>
        </Container>
      </Box>
    );
  }

  if (!proof) {
    return (
      <Box sx={{ py: 4, backgroundColor: bgColor }}>
        <Container>
          <Alert
            icon={<ErrorOutlineIcon fontSize="inherit" />}
            severity="error"
          >
            <Stack>
              <Typography>Proof not found</Typography>
              <Link
                component={NextLink}
                underline="hover"
                href={`${routes.orders}/${decode(order.id).id}`}
              >
                Back to order
              </Link>
            </Stack>
          </Alert>
        </Container>
      </Box>
    );
  }

  const modifiers = [
    {
      title: "Quantity",
      value: lineItem.quantity,
      fieldDefinitionTypeId: "",
    },
  ].concat(
    lineItem.fields
      .filter((f) => f.value)
      .filter((f) => {
        // TODO: We should filter the fields in the query instead
        return [
          "gid://glide/CoreMetafieldDefinitionType/MaterialRelationship",
          "gid://glide/CoreMetafieldDefinitionType/StickerSize",
          "gid://glide/CoreMetafieldDefinitionType/LabelSize",
          "gid://glide/CoreMetafieldDefinitionType/Shape",
        ].includes(f.config.fieldDefinitionType.id);
      })
      .map((f) => {
        return {
          fieldDefinitionTypeId: f.config.fieldDefinitionType.id,
          title: f.config.fieldDefinitionType.name,
          value: (
            <Metafield
              defId={f.config.fieldDefinitionType.id}
              value={f.value}
            />
          ),
        };
      })
  );

  const isApproved = lineItem.proofStatus.slug == "approved";

  const keys = proof?.template.keys;

  const size = modifiers.find((mod) => {
    return [
      "gid://glide/CoreMetafieldDefinitionType/StickerSize",
      "gid://glide/CoreMetafieldDefinitionType/LabelSize",
    ].includes(mod?.fieldDefinitionTypeId);
  });

  const proofFiles = getAllowedFiles(proof);

  const purposeName =
    proofFiles.length > 0 ? proofFiles[0].purposes[0].name : "";
  const proofImage = proofFiles.length > 0 ? proofFiles[0].proofViewUrl : "";
  const fullSizeImageUrl =
    proofFiles.length > 0 ? proofFiles[0].fullSizeUrl : "";

  return (
    <Box sx={{ py: 4, backgroundColor: bgColor }}>
      <Container>
        <Head>
          <title>Proof: {order.orderNumber}</title>
        </Head>

        {/* <ReviewCard /> */}

        <Grid container justifyContent="center">
          <Grid xs={12} md={10} lg={8}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                alignItems="center"
                justifyContent="center"
              >
                {size?.value ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Alert
                      icon={<StraightenIcon fontSize="inherit" />}
                      severity="info"
                    >
                      <Stack direction="row" spacing={1}>
                        <span>The size is</span> {size.value}
                      </Stack>
                    </Alert>
                  </Box>
                ) : null}
              </Box>

              {proofFiles.length > 1 ? (
                <ProofImageCarousel
                  images={proofFiles}
                  hasSeenAllImages={() => setHasSeenAllImages(true)}
                />
              ) : (
                <SectionProofImage
                  proofImage={proofImage}
                  href={fullSizeImageUrl}
                />
              )}

              <Stack
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {keys.map((key, idx) => {
                  return (
                    <Stack
                      key={idx}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box sx={{ color: textColor }}>{key.name}</Box>
                      <Box
                        mx={1}
                        sx={{
                          height: "4px",
                          width: "70px",
                          backgroundColor: key.fillColor || key.strokeColor,
                        }}
                      />

                      <Tooltip title={key.description}>
                        <IconButton disabled={!key.description}>
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  );
                })}
              </Stack>

              {proof?.designComments ? (
                <Card>
                  <CardHeader title="Design comments" />
                  <CardContent>
                    <Alert severity="info">{proof?.designComments}</Alert>
                  </CardContent>
                </Card>
              ) : null}

              <DesignApproval
                proofImages={proofFiles}
                proofImage={proofImage}
                proofInstructions={proof.instructions || ""}
                currentStep={lineItem.proofStatus.slug}
                orderNumber={order.orderNumber}
                proofId={proof.id}
                fullSizeImageUrl={fullSizeImageUrl}
                proofChecklist={sortedProofChecklist}
                checklistTitle={proof.template.checklistTitle}
                checklistDescription={proof.template.checklistDescription}
                checklistEnabled={proof.template.checklistEnabled}
                hasSeenAllImages={hasSeenAllImages}
              />

              <Card>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" component="h2" mb="10px">
                      Product details
                    </Typography>
                    {/* <Typography sx={{ color: "text.blue" }}>
                      <strong> {lineItem.name}</strong>
                    </Typography> */}

                    <Typography sx={{ color: "text.blue" }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={4}>
                      <Grid>
                        <Typography variant="subtitle1" component="h3">
                          Name
                        </Typography>
                        <Typography sx={{ color: "text.blue" }}>
                          {lineItem.product.name}
                        </Typography>
                      </Grid>
                      {/* <Grid>
                        <Typography variant="subtitle1" component="h3">
                          SKU
                        </Typography>
                        <Typography sx={{ color: "text.blue" }}>
                          {lineItem.sku}
                        </Typography>
                      </Grid> */}
                      {modifiers.map((modifier, idx) => {
                        return (
                          <Grid key={idx}>
                            <Typography variant="subtitle1" component="h3">
                              {modifier.title}
                            </Typography>
                            <Typography sx={{ color: "text.blue" }}>
                              {modifier.value}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Card>

              <Card>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" component="h2" mb="10px">
                      Product history
                    </Typography>
                    <Typography sx={{ color: "text.blue" }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack gap="40px" direction="column">
                      {lineItem.history.nodes.map((item, idx) => {
                        if (!item.detail) {
                          return <div key={idx}>No detail for {item.id}</div>;
                        }

                        const Cmp: any = mapped[item.detail.__typename];

                        return (
                          <Box key={idx}>
                            {Cmp ? (
                              <Suspense fallback={<div>Loading</div>}>
                                <Cmp {...item} />
                              </Suspense>
                            ) : (
                              <div>
                                Unmapped Timeline Entry {item.detail.__typename}
                              </div>
                            )}
                          </Box>
                        );
                      })}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Card>
            </Box>
          </Grid>
        </Grid>

        {/* <ModalWithVideo
        openVideoModal={openVideoModal}
        setOpenVideoModal={setOpenVideoModal}
      /> */}
      </Container>
    </Box>
  );
};

export default ProofTemplate;
