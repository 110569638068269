import { ItemMeta } from "@/types";
import { getCorkscrewBaseUrl } from "@/utils/config";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import MoreVertIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useState } from "react";

interface IProps {
  orderId: string;
  orderNumber: string;
  status: {
    name: string;
    slug: string;
    id: string;
  };
  proofImageUrl: string;
  customerArtworkImageUrl: string;
  fullSizeImageUrl?: string;
  modifiers: {
    title: string;
    value: ReactNode;
  }[];
  productName: string;
  proofId: string;
  instructions: string;
  myOrdersPagePath: string;
  meta: ItemMeta[];
  designNote?: string;
  isListView: boolean;
  name: string;
}

const getStatusText = (slug: string) => {
  console.log("slug", slug);
  switch (slug) {
    case "sent": {
      return "Awaiting feedback";
    }
    case "designInProgress":
    case "not-sent": {
      return "Design in progress";
    }
    case "approved": {
      return "Approved";
    }
    case "rejected": {
      return "Rejected";
    }
    case "printRejected": {
      return "Print Rejected";
    }
    case "awaitingArtwork": {
      return "Upload artwork";
    }
  }
};

const chooseRowImageUrl = (
  status: { name: string; id: string },
  proofImageUrl: string,
  customerArtworkImageUrl: string
): string => {
  const corkscrewBaseUrl = getCorkscrewBaseUrl();

  // TOOD: Put back in
  // switch (status) {
  //   case "Payment":
  //   case "Design":
  //     if (customerArtworkImageUrl && !proofImageUrl) {
  //       return `${customerArtworkImageUrl}`;
  //     } else if (!customerArtworkImageUrl && !proofImageUrl) {
  //       return awaitingArtwork;
  //     }
  // }

  return `${proofImageUrl}`;
};

const MoreActionsHeader = ({ open, handleClick, anchorEl, handleClose }) => {
  return (
    <>
      <IconButton disabled aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>{" "}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>More actions</MenuItem>
      </Menu>
    </>
  );
};

const findLinkUrl = (
  slug: string,
  orderId: string,
  proofId: string,
  myOrdersPagePath: string
): { text: string; href: string } => {
  switch (slug) {
    case "sent":
    case "not-sent":
      return {
        text: "",
        href: `${myOrdersPagePath}/${orderId}/proofs/${proofId}`,
      };
    default:
      return {
        text: "",
        href: `/orders/${orderId}/proofs/${proofId}`,
      };
  }
};

export const findStatusColour = (slug: string, theme: Theme) => {
  switch (slug) {
    case "approved":
      return {
        color: theme.colors.black,
        backgroundColor: theme.colors.greenAlt,
      };
    case "rejected":
    case "printRejected":
      return {
        color: theme.colors.white,
        backgroundColor: theme.colors.red,
      };
    case "sent":
    case "not-sent":
    case "designInProgress":
      return {
        color: theme.colors.black,
        backgroundColor: theme.colors.green,
      };
    default: {
      return {
        color: theme.colors.black,
        backgroundColor: theme.colors.greyPlaceholder,
      };
    }
  }
};

const OrderViewItem: React.FunctionComponent<IProps> = ({
  orderId,
  orderNumber,
  status,
  proofImageUrl,
  customerArtworkImageUrl,
  fullSizeImageUrl,
  modifiers,
  productName,
  proofId,
  instructions,
  myOrdersPagePath,
  meta,
  designNote,
  isListView,
  name,
}: IProps) => {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("OrderViewItem");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const link = findLinkUrl(status.slug, orderId, proofId, myOrdersPagePath);

  const thumbnailSize = isListView
    ? { width: 184, height: 200 }
    : { width: 242, height: 242 };
  const up = new URLSearchParams();
  up.set("width", thumbnailSize.width.toString());
  up.set("height", thumbnailSize.width.toString());

  useEffect(() => {
    if (!fullSizeImageUrl) {
      setError(true);
    }
  }, [fullSizeImageUrl]);

  return (
    <Card>
      <CardActionArea onClick={() => router.push(link.href)}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            direction={isListView && !isMobile ? "row" : "column"}
            spacing={2}
          >
            <Grid
              item
              sx={{
                position: "relative",
                width: isListView ? (isMobile ? "100%" : "200px") : "auto",
                flexShrink: 0,
                maxWidth: isListView ? 200 : "none",
              }}
            >
              {loading && !error && (
                <Skeleton
                  variant="rectangular"
                  width={isListView ? 200 : "100%"}
                  height={200}
                />
              )}
              {error ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "grey.200",
                  }}
                >
                  <BrokenImageIcon color="error" />
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: 1 }}
                  >
                    Image failed to load
                  </Typography>
                </Box>
              ) : (
                <CardMedia
                  component="img"
                  image={
                    (fullSizeImageUrl
                      ? fullSizeImageUrl + "?" + up.toString()
                      : null) || customerArtworkImageUrl
                  }
                  alt="Sample Image"
                  onLoad={() => {
                    setLoading(false);
                  }}
                  onError={() => {
                    setLoading(false);
                    setError(true);
                  }}
                  sx={{
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    maxHeight: isListView ? "none" : "200px",
                    objectFit: "cover",
                    display: loading ? "none" : "block", // Hide image until it loads or errors
                  }}
                />
              )}
            </Grid>
            <Grid item xs>
              {" "}
              <CardContent>
                <Stack
                  direction={{
                    xs: "column",
                  }}
                  spacing={{ xs: 1 }}
                >
                  {isListView ? (
                    <>
                      <Typography variant="h5">{productName}</Typography>

                      {modifiers.map((modifier, index) => {
                        return (
                          <Stack
                            key={index}
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Typography variant="h6">
                              {t(modifier.title)}:
                            </Typography>
                            <Typography variant="body1">
                              {modifier.value}
                            </Typography>
                          </Stack>
                        );
                      })}
                      {instructions ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            [theme.breakpoints.down("sm")]: {
                              display: "none",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: theme.typography.fontWeightMedium,
                            }}
                          >
                            {t("instructions")}:{" "}
                          </Typography>
                          <Typography>{instructions}</Typography>
                        </Box>
                      ) : null}
                      {designNote ? (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            fontWeight: theme.typography.fontWeightMedium,
                            [theme.breakpoints.down("sm")]: {
                              display: "flex",
                              flexDirection: "column",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: theme.typography.fontWeightMedium,
                            }}
                          >
                            Design comment:
                          </Typography>
                          <Typography>{designNote}</Typography>
                        </Grid>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Typography variant="h5" textAlign="center">
                        {name}
                      </Typography>
                    </>
                  )}
                </Stack>
                <Box
                  sx={{
                    padding: 1,
                    textAlign: "center",
                    fontSize: "14px",
                    marginTop: "14px",
                    borderRadius: "5px",
                    ...findStatusColour(status.slug, theme),
                  }}
                >
                  {getStatusText(status.slug)}
                </Box>
              </CardContent>
            </Grid>
          </Grid>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default OrderViewItem;
