import React from "react";
import { useUser } from "@/contexts/userContext";
import routes from "@/utils/routes";
import theme from "@/utils/theme";
import { Link } from "@mui/material";
import { default as NextLink } from "next/link";

const MyAccountLink: React.FC = () => {
  const {
    state: { shopifyOrderUrl },
  } = useUser();

  return (
    <Link component={NextLink} href="/">
      My Account
    </Link>
  );
};

export default MyAccountLink;
