import { uiState } from "@/contexts/uiState";
import Bugsnag from "@bugsnag/js";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import {
  Box,
  Dialog,
  DialogTitle,
  Skeleton,
  Typography,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import styled from "@emotion/styled";

const ImageWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: `calc(80vh - 160px)`,
}));

const ErrorWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  bgcolor: "grey.200",
}));

const FullWidthImgModal = ({
  isOpen,
  imgSrc,
  onClose,
}: {
  isOpen: boolean;
  imgSrc: string;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const handleOnLoad = () => {
    setLoading(false);
    setSuccess(true);
    setFailure(false);
  };

  const handleOnFailure = () => {
    setLoading(false);
    setSuccess(false);
    setFailure(true);

    Bugsnag.notify(new Error("Proof images failed to load"));
  };

  return (
    <Dialog maxWidth={"xl"} open={isOpen} onClose={onClose}>
      <DialogTitle>Full size</DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" width="500px" height="250px" />
        ) : null}

        {failure ? (
          <ErrorWrapper>
            <BrokenImageIcon color="error" />
            <Typography variant="body2" color="error" sx={{ marginLeft: 1 }}>
              Image failed to load. Try reloading the page.
            </Typography>
          </ErrorWrapper>
        ) : (
          <img
            src={imgSrc}
            alt="Proof image"
            onLoad={handleOnLoad}
            onError={handleOnFailure}
            style={{
              display: loading ? "none" : "block",
              maxWidth: "none",
              maxHeight: "none",
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ISectionProofImage {
  proofImage: string;
  href: string;
}

const SectionProofImage: React.FC<ISectionProofImage> = ({
  proofImage,
  href,
}: ISectionProofImage) => {
  const [ui] = useRecoilState(uiState);

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(true);
  const [failure, setFailure] = useState(false);

  const [isEnlargeImage, setEnlargeImage] = useState(false);

  const bgColor = ui.isLight ? ui.color1 : ui.color3;
  const gradientColor = ui.isLight ? ui.color2 : ui.color4;

  const isCheckeredStyles = ui.isCheckered
    ? {
        padding: "20px",
        backgroundColor: bgColor,
        backgroundImage: `
    linear-gradient(45deg, ${gradientColor} 25%, transparent 25%),
    linear-gradient(-45deg, ${gradientColor} 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, ${gradientColor} 75%),
    linear-gradient(-45deg, transparent 75%, ${gradientColor} 75%)
  `,
        backgroundSize: `36px 36px`,
        backgroundPosition: `0 0, 0 18px, 18px -18px, -18px 0px`,
      }
    : {};

  const handleOnLoad = () => {
    setLoading(false);
    setSuccess(true);
    setFailure(false);
  };

  const handleOnFailure = () => {
    setLoading(false);
    setSuccess(false);
    setFailure(true);

    Bugsnag.notify(new Error("Proof images failed to load"));
  };

  const carouselStyles = loading ? null : isCheckeredStyles;

  return (
    <>
      <FullWidthImgModal
        imgSrc={href}
        isOpen={isEnlargeImage}
        onClose={() => setEnlargeImage(false)}
      />
      <Box onClick={() => setEnlargeImage(true)}>
        <ImageWrapper
          sx={{
            ...carouselStyles,
          }}
        >
          {loading && !failure ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : null}

          {failure ? (
            <ErrorWrapper>
              <BrokenImageIcon color="error" />
              <Typography variant="body2" color="error" sx={{ marginLeft: 1 }}>
                Image failed to load. Try reloading the page.
              </Typography>
            </ErrorWrapper>
          ) : (
            <img
              src={proofImage}
              alt="Proof image"
              onLoad={handleOnLoad}
              onError={handleOnFailure}
              style={{
                display: loading ? "none" : "block", // Hide image until it loads or errors
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </ImageWrapper>
      </Box>
    </>
  );
};

export default React.memo(SectionProofImage);
